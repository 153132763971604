import BottomButton from '../../../components/Buttons/BottomButtonStyled';
import ContentBoardStyled from '../../../components/ContentBoardStyled';
import StyledBoardHeader from '../../../components/StyledBoardHeader';
import SelectInput from '../../../components/Inputs/SelectInput';
import SearchInput from '../../../components/Inputs/SearchInput';
import CountInput from '../../../components/Inputs/CountInput';
import { FiltersWrap } from '../../../components/Inputs/styles';
import doFetch from '../../../api/doFetch';
import React, { useEffect } from 'react';

import { isEqual } from 'lodash';
import {
  useMakes,
  useModels,
  useFilter,
  defaultValue,
  filteTorQuery,
  usePatchFilter,
  useResetFilter
} from '../context';

export default function () {
  const patchFilter = usePatchFilter();
  const resetFilter = useResetFilter();
  const filter = useFilter();
  const models = useModels();
  const makes = useMakes();

  const years = [
    'Any',
    ...Array(new Date().getFullYear() - 1900 + 1)
      .fill(new Date().getFullYear())
      .map((year, index) => year - index)
      .sort((a, b) => (b < a ? -1 : 1))
  ];

  const numberSet = [
    { name: 'No', values: { from: 0, to: 0 } },
    { name: '1 to 5', values: { from: 1, to: 5 } },
    { name: '5 to 10', values: { from: 5, to: 10 } },
    { name: '10 to 15', values: { from: 10, to: 15 } },
    { name: '15 to 20', values: { from: 15, to: 20 } },
    { name: '20 to 25', values: { from: 20, to: 25 } },
    { name: '25 to 30', values: { from: 25, to: 30 } },

    { name: '< 3', values: { to: 3 } },
    { name: '< 5', values: { to: 5 } },
    { name: '< 8', values: { to: 8 } },
    { name: '< 10', values: { to: 10 } },
    { name: '< 12', values: { to: 12 } },
    { name: '< 15', values: { to: 15 } },
    { name: '< 20', values: { to: 20 } },

    { name: '1+', values: { from: 1 } },
    { name: '3+', values: { from: 3 } },
    { name: '5+', values: { from: 5 } },
    { name: '8+', values: { from: 8 } },
    { name: '10+', values: { from: 10 } },
    { name: '15+', values: { from: 15 } },
    { name: 'Any', values: {} }
  ];

  useEffect(() => {
    if (filter?.models?.length) {
      const filtered = filter.models.filter((model) => models.includes(model));
      if (filtered?.length !== filter?.models?.length) {
        patchFilter({ models: filtered });
      }
    }
    // eslint-disable-next-line
  }, [models?.length]);

  const performExport = () => {
    doFetch(`/vehicles/download/scv?${filteTorQuery(filter)}`, { responseType: 'blob' })
      .then((response) => response.blob())
      .then((response) => {
        const file = new Blob([response], { type: `application/csv` });
        const fileURL = URL.createObjectURL(file);
        var a = document.createElement('a');
        a.href = fileURL;
        a.download = `vehicles-report.csv`;
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
      .catch((error) => {
        alert(error);
      });
  };

  return (
    <ContentBoardStyled css={{ minHeight: '130px', marginLeft: 0, marginTop: 0 }}>
      <StyledBoardHeader
        button={{
          name: 'Reset',
          handler: resetFilter
        }}
        disabledButton={isEqual(defaultValue, filter)}
      >
        FILTER
      </StyledBoardHeader>

      <FiltersWrap p={3}>
        <SearchInput
          label='VIN'
          placeholder='Enter VIN'
          value={filter?.vin || ''}
          onChange={(vin) => patchFilter({ vin })}
        />
        <SelectInput
          label={'Make'}
          array={makes || []}
          selectedItems={filter?.makes || []}
          setSelectedItems={(makes) => patchFilter({ makes })}
          disabled={Boolean(filter?.vin)}
        />
        <SelectInput
          label={'Model'}
          array={models || []}
          selectedItems={filter?.models || []}
          setSelectedItems={(models) => patchFilter({ models })}
          disabled={Boolean(filter?.vin)}
        />
        <SelectInput
          single={1}
          label={'Year'}
          array={years}
          selectedItems={filter?.year}
          setSelectedItems={(year) => {
            patchFilter({ year });
          }}
          disabled={Boolean(filter?.vin)}
          sort={false}
        />
        <SearchInput
          label='Model Number'
          placeholder='Value'
          value={filter?.modelNumber || ''}
          onChange={(modelNumber) => patchFilter({ modelNumber })}
          disabled={Boolean(filter?.vin)}
        />
        <SearchInput
          label='Exterior Color Description'
          placeholder='Enter color'
          value={filter?.exteriorColor || ''}
          onChange={(exteriorColor) => patchFilter({ exteriorColor })}
          disabled={Boolean(filter?.vin)}
        />
        <SearchInput
          label='Interior Color Code'
          placeholder='Enter code'
          value={filter?.interiorColor || ''}
          onChange={(interiorColor) => patchFilter({ interiorColor })}
          disabled={Boolean(filter?.vin)}
        />
        <CountInput
          label={'Photo amount'}
          selectedCount={filter.photoAmount}
          setSelectedCount={(photoAmount) => patchFilter({ photoAmount })}
          dataSet={numberSet}
          disabled={Boolean(filter?.vin)}
        />
        <SearchInput
          label='MSRP'
          placeholder='Value'
          value={filter?.msrp || ''}
          onChange={(msrp) => patchFilter({ msrp })}
          disabled={Boolean(filter?.vin)}
        />
        <CountInput
          label={'Invoice Amount'}
          selectedCount={filter.invoiceAmount}
          setSelectedCount={(invoiceAmount) => patchFilter({ invoiceAmount })}
          dataSet={numberSet}
          disabled={Boolean(filter?.vin)}
        />
      </FiltersWrap>
      <BottomButton onClick={performExport} disabled={isEqual(defaultValue, filter)}>
        Export EXCEL report
      </BottomButton>
    </ContentBoardStyled>
  );
}
